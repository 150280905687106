import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import LessonCardRenderer from "./LessonCardRenderer";
import {
  useContinueAssessmentMutation,
  useNextLessonCardQuery,
  useSubmitAssessmentMutation,
} from "./queries";
import DocumentTextMini from "assets/icons/document-text-mini.svg";

const ScreenerStudentAssessment = () => {
  const { id: studentId, studentAssessmentId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const nextLessonCardQuery = useNextLessonCardQuery();
  const submitAssessmentMutation = useSubmitAssessmentMutation();
  const completeAssessmentMutation = useContinueAssessmentMutation();
  const {
    assessmentName,
    assessmentScriptURL,
    hasEndWord,
    id,
    isLast,
    studentAssessmentSectionId,
    type,
  } = nextLessonCardQuery.data || {};
  let completeAssessmentPath;
  switch (type) {
    case "StudentOralReadingFluency":
      completeAssessmentPath = `/students/${studentId}/student_dear_assessment/${id}/complete_assessment`;
      break;
    case "StudentNonsenseWordFluency":
      completeAssessmentPath = `/students/${studentId}/student_nonsense_word_fluency/${id}/complete`;
      break;
    default:
      completeAssessmentPath = `/students/${studentId}/student_reading_challenge/${id}/complete`;
      break;
  }

  const onSubmitAssessment = useCallback(
    async (event) => {
      await completeAssessmentMutation.mutateAsync(completeAssessmentPath);
      await submitAssessmentMutation.mutateAsync(studentAssessmentSectionId, {});
      event.preventDefault();
    },
    [
      completeAssessmentMutation,
      completeAssessmentPath,
      submitAssessmentMutation,
      studentAssessmentSectionId,
    ]
  );
  const onUpdateWordEndCallback = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["next-lesson-card", studentId, studentAssessmentId],
    });
  }, [queryClient, studentAssessmentId, studentId]);
  const assessmentNameToTitleMap = {
    "BOP Screener Assessment": "Beginning of Program Screener",
    "MOP Screener Assessment": "Middle of Program Screener",
  };

  useEffect(() => {
    if (nextLessonCardQuery.data === null) {
      navigate(`/students/${studentId}/progress?studentAssessmentId=${studentAssessmentId}`);
    }
  }, [navigate, nextLessonCardQuery.data, studentAssessmentId, studentId]);

  if (!nextLessonCardQuery.data) {
    if (nextLessonCardQuery.isError) {
      return (
        <span className="flex items-center justify-center text-red-600 min-h-[200px]">
          Error: {nextLessonCardQuery.error.message}
        </span>
      );
    }

    return (
      <span className="flex items-center justify-center text-gray-400 min-h-[200px]">
        Loading...
      </span>
    );
  }

  return (
    <>
      <style>
        {`:root {
          --timer-sticky-top: 120px;
          --md-timer-sticky-top: 126px;
          --lg-timer-sticky-top: 65px;
        }`}
      </style>
      <div className="mt-4 border-b border-gray-200">
        <h2 className="pb-4 text-lg leading-6 font-medium text-gray-900">
          {assessmentNameToTitleMap[assessmentName] || assessmentName}
        </h2>
      </div>
      <div className="my-6 space-y-4">
        <p className="my-2 text-sm text-gray-500">
          The assessment is meant to give a clear understanding of the component skills involved in
          reading: Letter Naming Fluency (LNF), Nonsense Word Fluency (NWF) and Oral Reading Fluency
          (ORF). These assessments give us a timed measure of phonological awareness, phonics and
          fluency. These measures allow us to track and monitor student progress throughout the
          program.
        </p>
        {assessmentScriptURL ? (
          <p>
            <a
              className="flex items-center text-sm font-medium text-zinc-600 hover:text-zinc-800 hover:underline"
              href={assessmentScriptURL}
              target="_blank"
              rel="noreferrer"
            >
              <DocumentTextMini className="w-5 h-5 mr-1" />
              Script
            </a>
          </p>
        ) : null}
      </div>
      <LessonCardRenderer
        id={id}
        isLast={isLast}
        onCompleteAssessment={onSubmitAssessment}
        onUpdateWordEndCallback={onUpdateWordEndCallback}
        studentId={studentId}
        type={type}
      />
      <div className="flex flex-col justify-center items-center space-y-2 mt-8">
        {hasEndWord ? (
          <form onSubmit={onSubmitAssessment}>
            <button
              className="button-primary w-[200px] py-3.5"
              disabled={submitAssessmentMutation.isLoading || completeAssessmentMutation.isLoading}
              type="submit"
            >
              {isLast ? "Submit Assessment" : "Continue Assessment"}
            </button>
          </form>
        ) : (
          <Link className="button-primary w-[200px] py-3.5" to={`/students/${studentId}/progress`}>
            Complete Later
          </Link>
        )}
      </div>
    </>
  );
};

export default ScreenerStudentAssessment;
